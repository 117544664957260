<template>
  <main class="main" role="main">
    <div class="page bg">
      <div class="inner">
        <div class="page_con" id="page_con">
          <div class="page_tab">
            <div class="btn_wrap">
              <button class="on">
                참여파티</button
              ><!--'on'-->
              <button @click="fnMove()">
                생성파티
              </button>
            </div>
          </div>
          <SearchConditions
            :conditionOpen="conOpen"
            :conditionsList="conditionsList"
            :type="'PARTY'"
            :height="'h190'"
            :autoExtension="autoExtension"
            @update="fnUpdateConditionsList($event)"
            @updateAutoExtension="fnUpdateAutoExtension($event)"
            @updateOnOff="fnUpdateOnOffConditions($event)"
          />
          <div class="lump bo_zero">
            <label class="input srh_type02">
              <input
                type="search"
                placeholder="검색어를 입력해 주세요."
                :value="searchWord"
                @input="inputSearchWord"
                @keyup.enter="fnSearch"
              />
              <button
                v-if="searchWord"
                class="btn_search delete"
                @click="() => (searchWord = '')"
              ></button>
              <button class="btn_search" @click="fnSearch">검색</button>
            </label>
          </div>
          <div class="party_list_wrap">
            <div class="list_top">
              <div class="left">
                <button class="total">총 {{ totalCnt }}건</button>
              </div>
              <div class="right">
                <!-- 211223 추가 -->
                <button
                  class="li_btn up"
                  :class="{ on: order === 'ASC' }"
                  @click="() => (order = 'ASC')"
                ></button>
                <button
                  class="li_btn down"
                  :class="{ on: order === 'DESC' }"
                  @click="() => (order = 'DESC')"
                ></button>
                <!-- 211223 추가 끝 -->
                <label class="select">
                  <select v-model="sort">
                    <option :value="'PAYDT'">결제순</option>
                    <option :value="'DEADLINE'">마감순</option>
                    <option :value="'PERIOD'">기간순</option>
                  </select>
                </label>
              </div>
            </div>
            <div class="party_list row_type" v-if="!$Util.isEmpty(list)">
              <!--'가로형: row_type'-->
              <ul class="party_list_ul">
                <li
                  class="party_list_li type_02"
                  v-for="(item, index) in list"
                  :class="{
                    disabled:
                      item.status === '종료' || item.status === '취소/환불'
                  }"
                  :key="index"
                  @click="fnView(item)"
                >
                  <div class="party_tag">
                    <div class="left">
                      <div
                        class="pt_tag logo"
                        style="--pt-txt-color: #fff;"
                        :style="{
                          '--pt-bg-color': $ConstCode.getCategoryById(
                            item.categoryId
                          ).color
                        }"
                      >
                        {{ categoryNames[item.categoryId].text }}
                      </div>
                      <div class="pt_tag normal">
                        {{
                          item.acntTransferYn === 'N'
                            ? $Util.isNumeric(item.status)
                              ? `${item.status}일`
                              : item.status
                            : '양도'
                        }}
                      </div>
                      <div
                        class="pt_tag"
                        :class="{ new: Number(item.hasBadge) === 1 }"
                      ></div>
                    </div>
                    <div class="right">
                      <button
                        class="ext_gr"
                        v-if="
                          item.autoExtension === 'Y' &&
                            item.autoExtensionType == '1'
                        "
                        @click.stop="fnRequestExtensionCancel(item, index)"
                      >
                        연장취소
                      </button>
                    </div>
                  </div>
                  <div class="party_ttl">{{ item.title }}</div>
                  <div class="party_con">
                    <div class="left">
                      <div class="party_info">
                        <dl>
                          <dt>이용 기간 :</dt>
                          <dd v-if="item.unlimitedCheckYn === 'N'">
                            {{
                              `${$dayjs(item.regDt).format(
                                'YYYY.MM.DD'
                              )} ~ ${$dayjs(item.useDt)
                                .subtract(1, 'd')
                                .format('YYYY.MM.DD')}`
                            }}
                          </dd>
                          <dd v-else>{{ item.useDt }}</dd>
                        </dl>
                      </div>
                      <div class="party_info">
                        <dl>
                          <dt>파티장 :</dt>
                          <dd>{{ item.nick }}</dd>
                        </dl>
                      </div>
                    </div>
                    <div class="right">
                      <div class="party_price">
                        <span class="value">{{
                          $Util.formatNumber2(item.partyAmt, { unit: '' })
                        }}</span
                        ><!--띄어쓰기-->
                        <span class="unit">원</span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="empty_wrap" v-else>
              <div class="empty">
                <div>
                  아직 참여한 파티가 없습니다.
                </div>
              </div>
            </div>
            <observe-visible
              :key="initCnt"
              :page="page"
              :limit="limit"
              :totalCnt="totalCnt"
              @fetch="fnListFunction"
            />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { componentState } from './index.js'
import SearchConditions from '@/components/views/my-page/SearchConditions'

export default {
  name: 'my-page-joined-party-index',
  components: { SearchConditions },
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
